    // src/components/Header.js

    import React, { useEffect, useState } from 'react';
    import { Link, useNavigate, useLocation } from 'react-router-dom';
    import './Header.css';
    import { FaBars, FaTimes } from 'react-icons/fa';

    const Header = ({ isLoggedIn, setIsLoggedIn }) => {
        const navigate = useNavigate();
        const location = useLocation();
        const [isScrolled, setIsScrolled] = useState(false);
        const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

        const handleLogout = () => {
            localStorage.removeItem('token');
            localStorage.removeItem('isAdmin');
            setIsLoggedIn(false);
            navigate('/');
        };

        useEffect(() => {
            const handleScroll = () => {
                if (window.scrollY > 50) {
                    setIsScrolled(true);
                } else {
                    setIsScrolled(false);
                }
            };
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }, []);

        useEffect(() => {
            const checkAuthToken = () => {
                const token = localStorage.getItem('token');
                if (!token) {
                    setIsLoggedIn(false);
                }
            };

            checkAuthToken();
            const intervalId = setInterval(checkAuthToken, 300000);

            return () => clearInterval(intervalId);
        }, [setIsLoggedIn]);

        const isAuthPage = location.pathname !== '/';

        useEffect(() => {
            setIsMobileMenuOpen(false);
        }, [location]);

        useEffect(() => {
            if (isMobileMenuOpen) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
        }, [isMobileMenuOpen]);

        const isAdmin = localStorage.getItem('isAdmin') === 'true';

        return (
            <header className={`header ${isScrolled ? 'header-scrolled' : ''} ${isAuthPage ? 'auth-page' : ''}`}>
                <div className="container">
                    <div className="logo" onClick={() => navigate('/')}>
                        <img
                            src={isAuthPage ? '/logo-red.png' : '/logowhite.png'}
                            alt="İlanPorts Logo"
                            className="logo-image"
                        />
                    </div>
                    <div
                        className={`mobile-menu-icon ${isAuthPage ? 'auth-icon' : ''}`}
                        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                    >
                        {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
                    </div>
                    <div className="user-menu">
                        <nav className="nav-links">
                            <Link to="/ads">Tüm İlanlar</Link>
                            {isLoggedIn ? (
                                <>
                                    <Link to="/add-ad">İlan Ekle</Link>
                                    {isAdmin && <Link to="/admin">Admin Paneli</Link>}
                                    <Link to="/profile">Profil</Link>
                                    <button onClick={handleLogout}>Çıkış Yap</button>
                                </>
                            ) : (
                                <>
                                    <Link to="/login">Giriş Yap</Link>
                                    <Link to="/register">Kayıt Ol</Link>
                                </>
                            )}
                        </nav>
                    </div>
                </div>
                <div className={`mobile-overlay ${isMobileMenuOpen ? 'active' : ''}`}>
                    <nav className="mobile-nav-links">
                        <Link to="/ads" onClick={() => setIsMobileMenuOpen(false)}>Tüm İlanlar</Link>
                        {isLoggedIn ? (
                            <>
                                <Link to="/add-ad" onClick={() => setIsMobileMenuOpen(false)}>İlan Ekle</Link>
                                {isAdmin && <Link to="/admin" onClick={() => setIsMobileMenuOpen(false)}>Admin Paneli</Link>}
                                <Link to="/profile" onClick={() => setIsMobileMenuOpen(false)}>Profil</Link>
                                <button onClick={() => { handleLogout(); setIsMobileMenuOpen(false); }}>Çıkış Yap</button>
                            </>
                        ) : (
                            <>
                                <Link to="/login" onClick={() => setIsMobileMenuOpen(false)}>Giriş Yap</Link>
                                <Link to="/register" onClick={() => setIsMobileMenuOpen(false)}>Kayıt Ol</Link>
                            </>
                        )}
                    </nav>
                </div>
            </header>
        );
    };

    export default Header;