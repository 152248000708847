// frontend/src/components/Slider.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Slider.css';
import config from '../config';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaMapMarkerAlt, FaHeart, FaFire } from 'react-icons/fa';

const Slider = () => {
    const [ads, setAds] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSliderAds = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}/ads?premium=slider`);
                setAds(response.data.ads);
                setLoading(false);
            } catch (error) {
                console.error('Slider ads fetch error:', error);
                toast.error('Slider ilanlar çekilirken bir hata oluştu.');
                setLoading(false);
            }
        };

        fetchSliderAds();
    }, []);

    // Gerçekçi placeholder verileri
    const placeholderData = [
        { 
            id: 'placeholder-1', 
            title: 'Kiralık Lüks Villa', 
            price: '12,500', 
            currency: '₺', 
            location: 'Girne', 
            image: '/images/property1.jpg',
            category: 'Konut'
        },
        { 
            id: 'placeholder-2', 
            title: 'Satılık BMW X5', 
            price: '85,000', 
            currency: '₺', 
            location: 'Lefkoşa', 
            image: '/images/car1.jpg',
            category: 'Araç'
        },
        { 
            id: 'placeholder-3', 
            title: 'İş Merkezi Ofis', 
            price: '8,750', 
            currency: '₺', 
            location: 'Magosa', 
            image: '/images/office1.jpg',
            category: 'İş Yeri'
        },
        { 
            id: 'placeholder-4', 
            title: 'MacBook Pro 2023', 
            price: '45,000', 
            currency: '₺', 
            location: 'Girne', 
            image: '/images/laptop1.jpg',
            category: 'Elektronik'
        },
        { 
            id: 'placeholder-5', 
            title: 'Deniz Manzaralı Daire', 
            price: '7,500', 
            currency: '₺', 
            location: 'Girne', 
            image: '/images/apartment1.jpg',
            category: 'Konut'
        },
        { 
            id: 'placeholder-6', 
            title: 'Mercedes C200', 
            price: '75,000', 
            currency: '₺', 
            location: 'Lefkoşa', 
            image: '/images/car2.jpg',
            category: 'Araç'
        },
        { 
            id: 'placeholder-7', 
            title: 'Satılık İş Yeri', 
            price: '950,000', 
            currency: '₺', 
            location: 'Magosa', 
            image: '/images/store1.jpg',
            category: 'İş Yeri'
        },
        { 
            id: 'placeholder-8', 
            title: 'iPhone 15 Pro Max', 
            price: '52,000', 
            currency: '₺', 
            location: 'Lefkoşa', 
            image: '/images/phone1.jpg',
            category: 'Elektronik'
        },
        { 
            id: 'placeholder-9', 
            title: 'Havuzlu Villa', 
            price: '16,500', 
            currency: '₺', 
            location: 'Girne', 
            image: '/images/villa1.jpg',
            category: 'Konut'
        },
        { 
            id: 'placeholder-10', 
            title: 'Audi Q7', 
            price: '125,000', 
            currency: '₺', 
            location: 'Lefkoşa', 
            image: '/images/car3.jpg',
            category: 'Araç'
        }
    ];

    // Yeterli gerçek ilan yoksa, placeholder verileri ile doldurun
    const sliderAds = ads.length > 0 ? ads : placeholderData;

    // Premium özelliğin aktif olup olmadığını kontrol eden fonksiyon
    const isPremium = (ad) => {
        return ['featured', 'slider', 'showroom'].some(
            (feature) => ad[feature]?.isActive && new Date(ad[feature].expiryDate) > new Date()
        );
    };

    const getPremiumLabel = (ad) => {
        const now = new Date();
        if (ad.featured?.isActive && new Date(ad.featured.expiryDate) > now) {
          return 'VIP';
        }
        if (ad.slider?.isActive && new Date(ad.slider.expiryDate) > now) {
          return 'ACİL';
        }
        if (ad.showroom?.isActive && new Date(ad.showroom.expiryDate) > now) {
          return 'VIP+';
        }
        return 'ACİL';  // Tüm placeholderlar için ACİL etiketi göster
    };

    // Slider öğelerini oluşturun
    const sliderItems = sliderAds.map((ad, index) => {
        if (ad._id) { // Gerçek ilan
            return (
                <Link to={`/ad/${ad._id}`} key={ad._id + "-" + index} className="slider-item-link">
                    <div className="slider-item">
                        <div className="ribbon">
                            <span>{getPremiumLabel(ad)}</span>
                        </div>
                        <div className="slider-image-container">
                            <img
                                src={
                                    ad.images && ad.images.length > 0
                                        ? (ad.images[0].startsWith('http') 
                                            ? ad.images[0] 
                                            : `${config.baseUrl}/uploads/${ad.images[0]}`)
                                        : '/placeholder-image.png'
                                }
                                alt={ad.title}
                                className="slider-image"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "/placeholder-image.png";
                                }}
                            />
                            <div className="slider-category">{ad.category}</div>
                            <div className="slider-save"><FaHeart /></div>
                        </div>
                        <div className="slider-info">
                            <h3>{ad.title}</h3>
                            <p className="price">{ad.price} {ad.currency}</p>
                            <p className="location"><FaMapMarkerAlt className="location-icon" /> {ad.location}</p>
                        </div>
                        <div className="urgent-badge">
                            <FaFire className="fire-icon" /> Acil Satılık
                        </div>
                    </div>
                </Link>
            );
        } else { // Placeholder
            return (
                <div key={ad.id + "-" + index} className="slider-item-link">
                    <div className="slider-item">
                        <div className="ribbon">
                            <span>ACİL</span>
                        </div>
                        <div className="slider-image-container">
                            <img 
                                src={ad.image || "/placeholder-image.png"} 
                                alt={ad.title} 
                                className="slider-image"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "/placeholder-image.png";
                                }}
                            />
                            <div className="slider-category">{ad.category}</div>
                            <div className="slider-save"><FaHeart /></div>
                        </div>
                        <div className="slider-info">
                            <h3>{ad.title}</h3>
                            <p className="price">{ad.price} {ad.currency}</p>
                            <p className="location"><FaMapMarkerAlt className="location-icon" /> {ad.location}</p>
                        </div>
                        <div className="urgent-badge">
                            <FaFire className="fire-icon" /> Acil Satılık
                        </div>
                    </div>
                </div>
            );
        }
    });

    return (
        <div className="slider">
            <div className="slider-track">
                {loading ? (
                    // Yükleme durumunda iskelet göster
                    Array(5).fill().map((_, index) => (
                        <div key={`skeleton-${index}`} className="slider-item skeleton-item">
                            <div className="skeleton-image"></div>
                            <div className="skeleton-text"></div>
                            <div className="skeleton-price"></div>
                            <div className="skeleton-location"></div>
                        </div>
                    ))
                ) : (
                    // Kesintisiz döngü için öğeleri iki kez ekleme
                    <>
                        {sliderItems}
                        {sliderItems}
                    </>
                )}
            </div>
        </div>
    );
};

export default Slider;
