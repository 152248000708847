import React from 'react';
import './AppPromo.css'; // Stil dosyasını içe aktarıyoruz

const AppPromo = () => {
    return (
        <div className="promo-section">
            {/* Sol: İlanPorts Mobil Uygulama */}
            <div className="promo-app ilanports-app">
                <div className="app-icon">
                    <i className="fas fa-bullhorn"></i>
                </div>
                
                <h2>İlanPorts Mobil</h2>
                <p>İlanlarınıza her yerden erişin, yeni fırsatları anında yakalayın!</p>
                
                <div className="features-container">
                    <div className="feature-item">
                        <i className="fas fa-bell"></i>
                        <span>Anlık bildirimler</span>
                    </div>
                    <div className="feature-item">
                        <i className="fas fa-search"></i>
                        <span>Hızlı arama</span>
                    </div>
                    <div className="feature-item">
                        <i className="fas fa-comment"></i>
                        <span>Anında mesajlaşma</span>
                    </div>
                </div>
                
                <div className="download-buttons">
                    <a href="#" className="download-button google-play" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-google-play"></i> Google Play
                    </a>
                    <a href="#" className="download-button app-store" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-apple"></i> App Store
                    </a>
                </div>
            </div>

            {/* Sağ: TencereApp */}
            <div className="promo-app tencereapp">
                <div className="app-icon">
                    <i className="fas fa-utensils"></i>
                </div>
                
                <h2>TencereApp</h2>
                <p>Evinizdeki malzemelerle harika tarifler keşfedin!</p>
                
                <div className="features-container">
                    <div className="feature-item">
                        <i className="fas fa-apple-alt"></i>
                        <span>Malzemelere göre tarifler</span>
                    </div>
                    <div className="feature-item">
                        <i className="fas fa-clock"></i>
                        <span>Zaman tasarrufu</span>
                    </div>
                    <div className="feature-item">
                        <i className="fas fa-heart"></i>
                        <span>Favori tarifleriniz</span>
                    </div>
                </div>
                
                <div className="download-buttons">
                    <a href="https://play.google.com/store/apps/details?id=com.zmkdev.tencere" 
                       className="download-button google-play" 
                       target="_blank" 
                       rel="noopener noreferrer">
                        <i className="fab fa-google-play"></i> Google Play
                    </a>
                    <a href="https://apps.apple.com/tr/app/tencereapp/id6739714418?l=tr" 
                       className="download-button app-store" 
                       target="_blank" 
                       rel="noopener noreferrer">
                        <i className="fab fa-apple"></i> App Store
                    </a>
                </div>
            </div>
        </div>
    );
};

export default AppPromo;
