import React from 'react';
import './Banner.css'; // Banner için özel stil dosyası
import { FaSearch, FaHome, FaBuilding, FaCar, FaShoppingBag, FaPlusCircle } from 'react-icons/fa';
import { IoBoat } from 'react-icons/io5';
import { MdRealEstateAgent } from 'react-icons/md';

const Banner = () => {
    return (
        <div className="banner">
            {/* Arka plan ve overlay */}
            <div className="banner-overlay"></div>
            
            {/* Dekoratif hareketli şekiller */}
            <div className="shape shape1"></div>
            <div className="shape shape2"></div>
            <div className="shape shape3"></div>
            <div className="shape shape4"></div>
            
            {/* Ana içerik */}
            <div className="banner-content">
                <div className="text-content">
                    <div className="title-container">
                        <h1>
                            Kıbrıs'ta Almanın ve Satmanın <span className="highlight">En Kolay Yolu!</span>
                        </h1>
                        <div className="subtitle-container">
                            <p>Adanın En Büyük İlan Platformu • <span className="stats">15.000+ Aktif İlan</span></p>
                        </div>
                    </div>
                    
                    <div className="search-container">
                        <div className="search-box">
                            <input type="text" placeholder="Ne arıyorsunuz?" />
                            <button className="search-button">
                                <FaSearch />
                            </button>
                        </div>
                    </div>
                </div>
                
                {/* Kategori kutuları */}
                <div className="category-boxes">
                    <div className="category-box">
                        <div className="category-icon-container">
                            <FaHome className="category-icon" />
                        </div>
                        <span>Konut</span>
                    </div>
                    <div className="category-box">
                        <div className="category-icon-container">
                            <FaBuilding className="category-icon" />
                        </div>
                        <span>İş Yeri</span>
                    </div>
                    <div className="category-box">
                        <div className="category-icon-container">
                            <FaCar className="category-icon" />
                        </div>
                        <span>Araç</span>
                    </div>
                    <div className="category-box">
                        <div className="category-icon-container">
                            <FaShoppingBag className="category-icon" />
                        </div>
                        <span>Eşya</span>
                    </div>
                    <div className="category-box">
                        <div className="category-icon-container">
                            <IoBoat className="category-icon" />
                        </div>
                        <span>Deniz Araçları</span>
                    </div>
                    <div className="category-box">
                        <div className="category-icon-container">
                            <MdRealEstateAgent className="category-icon" />
                        </div>
                        <span>Arsa</span>
                    </div>
                </div>
                
                {/* İlan ver butonu */}
                <div className="post-ad-button-container">
                    <a href="/add-ad" className="post-ad-button">
                        <FaPlusCircle className="button-icon" />
                        <span>Hemen İlan Ver</span>
                    </a>
                </div>
            </div>
            
            {/* Aşağı kaydırma göstergesi - Kaldırıldı */}
        </div>
    );
};

export default Banner;