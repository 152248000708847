import React from 'react';
import './AdTemplate.css'; // Şablon stil dosyasını ekleyelim
import { FaMapMarkerAlt, FaHeart, FaRegClock } from 'react-icons/fa';

const AdTemplate = () => {
    // Örnek kategoriler ve başlıklar
    const templates = [
        {
            category: 'Konut',
            title: 'Satılık Daire',
            price: '950,000 ₺',
            location: 'Girne',
            image: '/images/property-template.jpg'
        },
        {
            category: 'Araç',
            title: 'Kiralık Araç',
            price: '4,500 ₺',
            location: 'Lefkoşa',
            image: '/images/car-template.jpg'
        },
        {
            category: 'İş Yeri',
            title: 'Kiralık Ofis',
            price: '25,000 ₺',
            location: 'Magosa',
            image: '/images/office-template.jpg'
        },
        {
            category: 'Elektronik',
            title: 'Yeni Cep Telefonu',
            price: '18,500 ₺',
            location: 'Lefkoşa',
            image: '/images/phone-template.jpg'
        },
        {
            category: 'Deniz Aracı',
            title: 'Satılık Tekne',
            price: '1,250,000 ₺',
            location: 'Girne',
            image: '/images/boat-template.jpg'
        }
    ];

    // Rastgele bir şablon seç
    const randomTemplate = templates[Math.floor(Math.random() * templates.length)];

    return (
        <div className="item-card template-card">
            <div className="category-tag">{randomTemplate.category}</div>
            <div className="save-icon"><FaHeart /></div>
            <div className="image-container">
                <img 
                    src={randomTemplate.image || "/placeholder-image.png"} 
                    alt={randomTemplate.title} 
                    className="item-image"
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/placeholder-image.png";
                    }}
                />
            </div>
            <div className="item-info">
                <h3>{randomTemplate.title}</h3>
                <p className="price">{randomTemplate.price}</p>
                <div className="location-wrapper">
                    <FaMapMarkerAlt className="location-icon" />
                    <p className="location">{randomTemplate.location}</p>
                </div>
                <div className="listing-date">
                    <FaRegClock className="time-icon" /> Yeni İlan
                </div>
            </div>
        </div>
    );
};

export default AdTemplate;