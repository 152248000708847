// frontend/src/components/FeaturedAds.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './FeaturedAds.css';
import config from '../config';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaMapMarkerAlt, FaHeart, FaCrown, FaStar, FaEye, FaRegClock } from 'react-icons/fa';

const FeaturedAds = () => {
    const [ads, setAds] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchFeaturedAds = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}/ads?premium=featured`);
                setAds(response.data.ads);
                setLoading(false);
            } catch (error) {
                console.error('Featured ads fetch error:', error);
                toast.error('Featured ilanlar çekilirken bir hata oluştu.');
                setLoading(false);
            }
        };

        fetchFeaturedAds();
    }, []);

    // Gerçekçi placeholder veriler
    const placeholderData = [
        {
            id: 'vip-1',
            title: 'Deniz Manzaralı Lüks Villa',
            price: '2,450,000',
            currency: '₺',
            location: 'Girne, Çatalköy',
            images: ['/images/luxury-villa.jpg'],
            category: 'Villa',
            date: 'Bugün',
            views: '124',
            features: ['5+1', '450m²', 'Havuzlu']
        },
        {
            id: 'vip-2',
            title: 'Range Rover Sport 2023',
            price: '950,000',
            currency: '₺',
            location: 'Lefkoşa, Gönyeli',
            images: ['/images/range-rover.jpg'],
            category: 'Arazi Aracı',
            date: '2 gün önce',
            views: '246',
            features: ['Dizel', 'Otomatik', '1500 km']
        },
        {
            id: 'vip-3',
            title: 'Sahile 100m Kiralık İş Yeri',
            price: '18,500',
            currency: '₺',
            location: 'Gazimağusa, Merkez',
            images: ['/images/commercial.jpg'],
            category: 'Dükkan',
            date: '1 gün önce',
            views: '87',
            features: ['250m²', 'Restoran', 'Hazır İşletme']
        },
        {
            id: 'vip-4',
            title: 'Satılık Lüks Yat',
            price: '5,750,000',
            currency: '₺',
            location: 'Girne, Marina',
            images: ['/images/yacht.jpg'],
            category: 'Deniz Aracı',
            date: '3 gün önce',
            views: '152',
            features: ['35ft', '2x350HP', 'Full Donanım']
        }
    ];

    const displayedAds = ads.length > 0 ? ads.slice(0, 4) : placeholderData;

    const isPremium = (ad) => {
        return ['featured', 'slider', 'showroom'].some(
            (feature) => ad[feature]?.isActive && new Date(ad[feature].expiryDate) > new Date()
        );
    };

    const getPremiumLabel = (ad) => {
        const now = new Date();
        if (ad.featured?.isActive && new Date(ad.featured.expiryDate) > now) {
          return 'VIP';
        }
        if (ad.slider?.isActive && new Date(ad.slider.expiryDate) > now) {
          return 'ACİL';
        }
        if (ad.showroom?.isActive && new Date(ad.showroom.expiryDate) > now) {
          return 'Showroom';
        }
        return 'VIP';  // Placeholder için varsayılan değer
    };

    // Yükleme durumu için iskelet bileşeni
    if (loading) {
        return (
            <div className="featured-ads">
                {Array(4).fill().map((_, index) => (
                    <div key={`skeleton-${index}`} className="featured-item skeleton-featured">
                        <div className="skeleton-image"></div>
                        <div className="skeleton-content">
                            <div className="skeleton-title"></div>
                            <div className="skeleton-price"></div>
                            <div className="skeleton-location"></div>
                            <div className="skeleton-features"></div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className="featured-ads">
            {displayedAds.map((ad, index) => (
                <div key={ad._id || ad.id || index} className="featured-item-link">
                    <div className={`featured-item ${isPremium(ad) || true ? 'premium' : ''}`} style={{ '--i': index }}>
                        <div className="vip-badge">
                            <FaCrown className="vip-icon" /> {getPremiumLabel(ad)}
                        </div>
                        
                        <div className="featured-image-container">
                            <img
                                src={
                                    (ad.images && ad.images.length > 0 && ad._id)
                                        ? (ad.images[0].startsWith('http') 
                                            ? ad.images[0] 
                                            : `${config.baseUrl}/uploads/${ad.images[0]}`)
                                        : ad.images?.[0] || '/placeholder-image.png'
                                }
                                alt={ad.title}
                                className="featured-image"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "/placeholder-image.png";
                                }}
                            />
                            
                            <div className="featured-category">
                                {ad.category || "Kategori"}
                            </div>
                            
                            <div className="featured-actions">
                                <span className="action-button save-button">
                                    <FaHeart />
                                </span>
                                <span className="stats-indicator">
                                    <FaEye /> {ad.views || "100+"}
                                </span>
                            </div>
                            
                            <div className="featured-date">
                                <FaRegClock /> {ad.date || "Yeni"}
                            </div>
                        </div>
                        
                        <div className="featured-info">
                            <h3>{ad.title}</h3>
                            <p className="price">{ad.price} {ad.currency}</p>
                            <p className="location">
                                <FaMapMarkerAlt className="location-icon" /> {ad.location}
                            </p>
                            
                            <div className="features-list">
                                {(ad.features || ['Emlak', 'VIP İlan']).map((feature, idx) => (
                                    <span key={idx} className="feature">
                                        <FaStar className="feature-icon" /> {feature}
                                    </span>
                                ))}
                            </div>
                        </div>
                        
                        <Link to={ad._id ? `/ad/${ad._id}` : '#'} className="view-details-button">
                            İlanı Görüntüle
                        </Link>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FeaturedAds;
