    // frontend/src/components/Showroom.js

    import React, { useEffect, useState } from 'react';
    import axios from 'axios';
    import './Showroom.css';
    import config from '../config';
    import Slider from './Slider';
    import FeaturedAds from './FeaturedAds';
    import AdTemplate from './AdTemplate';
    import ShowroomAdsTemplate from './ShowroomAdsTemplate';
    import { toast } from 'react-toastify';
    import { useNavigate } from 'react-router-dom';

    const Showroom = () => {
        const [ads, setAds] = useState([]);
        const navigate = useNavigate();
        const [loading, setLoading] = useState(true);

        useEffect(() => {
            fetchAds();
        }, []);

        const fetchAds = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}/ads?premium=showroom`);
                setAds(response.data.ads);
                setLoading(false);
            } catch (error) {
                console.error('İlanlar getirilirken hata:', error);
                toast.error('İlanlar getirilirken bir hata oluştu.');
                setLoading(false);
            }
        };

        if (loading) {
            return (
                <div className="showroom-ads-container">
                    {Array.from({ length: 7 }).map((_, index) => (
                        <div key={index} className="item-card skeleton">
                            <div className="skeleton-image"></div>
                            <div className="skeleton-text"></div>
                            <div className="skeleton-price"></div>
                        </div>
                    ))}
                </div>
            );
        }

        const totalPositions = 21; // 3 satır x 7 sütun
        const adPositions = [];
        for (let row = 0; row < 3; row++) {
            const randomIndexInRow = Math.floor(Math.random() * 7);
            const index = row * 7 + randomIndexInRow;
            adPositions.push(index);
        }

        const displayAds = [];
        let adIndex = 0;

        for (let i = 0; i < totalPositions; i++) {
            if (adPositions.includes(i)) {
                displayAds.push({ type: 'ad', key: `ad-${i}` });
            } else {
                if (adIndex < ads.length) {
                    const ad = ads[adIndex];
                    displayAds.push({ type: 'adItem', ad: ad });
                    adIndex++;
                } else {
                    displayAds.push({ type: 'placeholder', key: `placeholder-${i}` });
                }
            }
        }

        return (
            <div className="showroom">
                <h2 className="section-title">Ports'dan Acil</h2>
                <Slider />

                <h2 className="section-title">Ports VIP</h2>
                <FeaturedAds />

                <h2 className="section-title">Ports Showroom</h2>
                <div className="showroom-ads-container">
                    {displayAds.map((item, index) => {
                        if (item.type === 'ad') {
                            return (
                                <div key={item.key} className="item-card">
                                    <ShowroomAdsTemplate />
                                </div>
                            );
                        } else if (item.type === 'adItem') {
                            const ad = item.ad;
                            return (
                                <div
                                    key={ad._id}
                                    className="item-card"
                                    onClick={() => navigate(`/ad/${ad._id}`)}
                                >
                                    <div className="image-container">
                                        <img
                                            src={
                                                ad.images && ad.images.length > 0
                                                    ? (ad.images[0].startsWith('http') 
                                                        ? ad.images[0] 
                                                        : `${config.baseUrl}/uploads/${ad.images[0]}`)
                                                    : '/placeholder-image.png'
                                            }
                                            alt={ad.title}
                                            className="item-image"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = "/placeholder-image.png";
                                            }}
                                        />
                                    </div>
                                    <div className="item-info">
                                        <h3>{ad.title}</h3>
                                        <p className="price">{ad.price} {ad.currency}</p>
                                        <p className="location">{ad.location}</p>
                                        <div className="premium-badges">
                                            {Object.keys(ad)
                                                .filter(key => ['featured', 'slider', 'showroom'].includes(key))
                                                .map((feature) => (
                                                    ad[feature]?.isActive && new Date(ad[feature].expiryDate) > new Date() && (
                                                        <span key={feature} className={`premium-badge ${feature}`}>
                                                            {feature.toUpperCase()}
                                                        </span>
                                                    )
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            );
                        } else if (item.type === 'placeholder') {
                            return <AdTemplate key={item.key} />;
                        } else {
                            return null;
                        }
                    })}
                </div>
            </div>
        );
    };

    export default Showroom;
