// AdPopup.js

import React, { useState, useEffect, useRef } from 'react';
import './AdPopup.css';

const AdPopup = () => {
    const [isVisible, setIsVisible] = useState(false); // Başlangıçta görünmez olarak ayarla
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [isMuted, setIsMuted] = useState(true);
    const videoRef = useRef(null);
    const overlayRef = useRef(null); // Overlay'a referans
    
    // Popup'ın görünüp görünmeyeceğini kontrol et
    useEffect(() => {
        const checkPopupVisibility = () => {
            const lastShownTime = localStorage.getItem('adPopupLastShown');
            const currentTime = new Date().getTime();
            
            // 30 dakika = 1800000 milisaniye
            const popupInterval = 30 * 60 * 1000;
            
            // Eğer daha önce gösterilmemişse veya 30 dakika geçmişse
            if (!lastShownTime || (currentTime - parseInt(lastShownTime)) > popupInterval) {
                setIsVisible(true);
                // Şimdiki zamanı kaydet
                localStorage.setItem('adPopupLastShown', currentTime.toString());
            }
        };
        
        checkPopupVisibility();
    }, []);

    useEffect(() => {
        // Popup görünür olduğunda
        if (isVisible) {
            // 3 saniye sonra kapatma butonunu göster
            const timer = setTimeout(() => {
                setShowCloseButton(true);
            }, 3000);

            // Video otomatik oynatılır
            if (videoRef.current) {
                videoRef.current.play().catch(error => {
                    console.error('Video oynatılırken hata:', error);
                });
            }

            return () => clearTimeout(timer);
        }
    }, [isVisible]);

    const handleClose = () => {
        // Kapanış animasyonu için sınıf ekleyelim
        if (overlayRef.current) {
            overlayRef.current.classList.add('fade-out');
            setTimeout(() => setIsVisible(false), 600);
        }
    };

    const toggleMute = () => {
        if (videoRef.current) {
            setIsMuted(!isMuted);
            videoRef.current.muted = !isMuted;
        }
    };

    if (!isVisible) return null;

    return (
        <div className="modal-overlay" ref={overlayRef}>
            <div className="modal-content fade-in">
                <video
                    ref={videoRef}
                    src="/adsvideo.mp4"
                    autoPlay
                    muted={isMuted}
                    playsInline
                ></video> 
                {showCloseButton && (
                    <button className="close-button" onClick={handleClose} aria-label="Kapatma Butonu">✖</button>
                )}
                {/* Ses aç/kapa butonu */}
                <button className="mute-button" onClick={toggleMute} aria-label="Ses Aç/Kapa">
                    {isMuted ? '🔇' : '🔊'}
                </button>
            </div>
        </div>
    );
};

export default AdPopup;